import React from 'react';
import style from './IndexGuidePortal.module.scss';
import { ButtonCamera, ButtonLocation } from '../../buttons/Buttons'

const info = `
  We’re sorry, this intervention is only accessible on Fridays. 
  To visit it on any other weekday, please do one of the following:
`

const locationAccess = `Go to the location indicated on the map and allow this website to use your geolocation.`
// or

const printAccess = `Order your map here to get access to all interventions at all times regardless of your location.`

const IndexGuidePortal = ({ onClick, text }) => {

  const emptyFunction = () => ''

  return (
    <div 
    className={style.guide} 
    onClick={onClick || emptyFunction}
    >
        {/* asfasfsaf */}
        <div className={style.guideWrapper}>
          <div dangerouslySetInnerHTML={{__html: text || info}}/>
          <div className={style.symbol}>
            <ButtonLocation color="black"/>
          </div>
          <div dangerouslySetInnerHTML={{__html: locationAccess}} />
          <div className={style.symbol}>
            <ButtonCamera color="black" />
          </div>
          <div dangerouslySetInnerHTML={{__html: printAccess}} />
        </div>
    </div>
  )
}

export default IndexGuidePortal