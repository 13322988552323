import React from 'react'
import StoryblokClient from 'storyblok-js-client';
// const StoryblokClient = require('storyblok-js-client')
// require('dotenv').config()

let Storyblok = new StoryblokClient({
  accessToken: ''
})


const RichText = ({ text, className }) => {
  if(!text){
    return false
  }
  if(!text.content) return <p className={className} dangerouslySetInnerHTML={{__html: text }} />
  
  const richText = Storyblok.richTextResolver.render(text)

    
  return (
    <div className={className} data-type="richtext">
      <div dangerouslySetInnerHTML={{__html: richText }} />
    </div> 
  )
}


  export default RichText