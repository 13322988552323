// function measure(lat1, lon1, lat2, lon2){  // generally used geo measurement function
//     var R = 6378.137; // Radius of earth in KM
//     var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
//     var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
//     var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
//     Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
//     Math.sin(dLon/2) * Math.sin(dLon/2);
//     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//     var d = R * c;
//     return d * 1000; // meters
// }

//  Handle access globally
const calculateDistance = (userLocation, location) => {
    // console.log(userLocation, location) is 
    // const lng = Math.abs(parseFloat(userLocation.lng) - parseFloat(location.lng));
    // const lat = Math.abs(parseFloat(userLocation.lat) - parseFloat(location.lat));
    const lng = Math.abs(parseFloat(userLocation[0]) - parseFloat(location.lng));
    const lat = Math.abs(parseFloat(userLocation[1]) - parseFloat(location.lat));

    // const distanceInMeters = measure(location.lat, userLocation[1], location.lng, userLocation[0])
    // console.log('distanceInMeters', distanceInMeters)

    const distance = Math.sqrt(lng*lng + lat*lat)
    // console.log('distance between user and location', distance, 'max: 0.01')
    return distance < 0.01
}

const access = {
    // `Redirection Berlin`,
    password: false,
    webcam: false,
    userLocation: false, 
    // webcam: true,
    location: {
        0:  (userLocation, location) => calculateDistance(userLocation, location),
        1:  (userLocation, location) => calculateDistance(userLocation, location),
        2:  (userLocation, location) => calculateDistance(userLocation, location),
        3:  (userLocation, location) => calculateDistance(userLocation, location),
        4:  (userLocation, location) => calculateDistance(userLocation, location),
        5:  (userLocation, location) => calculateDistance(userLocation, location),
        6:  (userLocation, location) => calculateDistance(userLocation, location),
        7:  (userLocation, location) => calculateDistance(userLocation, location),
        8:  (userLocation, location) => calculateDistance(userLocation, location),
        9:  (userLocation, location) => calculateDistance(userLocation, location),
        10: (userLocation, location) => calculateDistance(userLocation, location),
        11: (userLocation, location) => calculateDistance(userLocation, location),
        12: (userLocation, location) => calculateDistance(userLocation, location),
        13: (userLocation, location) => calculateDistance(userLocation, location),
    },
    timeslot: {
        0: new Date().getDay() === 1 || 0,
        1: new Date().getDay() === 1 || 0,
        2: new Date().getDay() === 2 || 0,
        3: new Date().getDay() === 2 || 0,
        4: new Date().getDay() === 3 || 0,
        5: new Date().getDay() === 3 || 0,
        6: new Date().getDay() === 4 || 0,
        7: new Date().getDay() === 4 || 0,
        8: new Date().getDay() === 5 || 0,
        9: new Date().getDay() === 5 || 0,
        10: new Date().getDay() === 6 || 0,
        11: new Date().getDay() === 6 || 0,
        12: new Date().getDay() === 7 || 0,
        13: new Date().getDay() === 7 || 0,
    }
}

export default access