import React, { useEffect, useRef, useState } from 'react'
import { stateContext } from 'react-three-fiber'
import style from './intro.module.scss'


const Letter = ({text, i, angleOffset}) => {

    const ref = useRef()
    const spacing = 10.50

    useEffect(() => {
        ref.current.style.transform = `translate(-50%, -100%) rotate(${i*spacing+angleOffset}deg)`
        ref.current.style.color = `white`
    })

    return(

        <span
        ref={ref}
        className={style.letter}
        style={{
            transform: `translate(-50%, -spacing0%) rotate(${i*10}deg)`
        }}
        >
            {text}
        </span>
    )
}

const Intro = ({ textString, kerning }) => {

    const textArray = textString.split('')
    
    let totalOffset = 0

    const [ state, setState] = useState({
        introVisible: true
    })

    useEffect(() => {

        let timeout = setTimeout(() => {
            // setState({
            //     introVisible: false
            // })
        }, 1000)

        return () => { clearTimeout(timeout) }
    })

    return(
        <>
            {state.introVisible ?
                <div 
                onClick={() => { setState({...stateContext, introVisible: false })}}
                className={style.intro}>
                    <div className={style.wrapper}>
                    {
                        textArray.map((letter, i) => {

                            totalOffset += kerning && kerning[i] ? kerning[i] : 0
                            return <Letter key={i} i={i} angleOffset={totalOffset} text={letter}/>
                        })    
                    }
                    </div>
                </div>
            
            : false}
        </>
    )
}

export default Intro