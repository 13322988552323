import React, { useState } from 'react'
import style from './Buttons.module.scss'

const ButtonWrapper = ({ onClick, children, className, guide }) => {

    let hoverProps = false

    const [ state, setState ] = useState({
        hover: false,
    })
    if(guide){
        hoverProps = {
            onMouseEnter: () => { setState({...state, hover: true})},
            onMouseLeave: () => { setState({...state, hover: false})}
        }
    }
    
    return(
        <>
            <div 
            className={`${style.menuButton} ${className}`}
            onClick={onClick}
            role="button"
            aria-label="button"
            tabIndex={0}
            onKeyDown={onClick}
            {...hoverProps}
            >
                {children}
                {state.hover && guide ?  
                <div className={style.guide} >
                    <div>{guide}</div>
                    <div>
                        <svg>
                            <path
                                fill="white"
                                d="M 1 1 L 40 10 L 1 19 L 1 1"
                            />
                        </svg>
                    </div>
                </div>
                : false}
            </div>
        </>
    )
}

export default ButtonWrapper
