const kerning = {
    redirection_berlin: [
        0,
        2, //Re
        1.5, //ed
        -2, //di
        -4, //ir
        -2, //re
        -0, //ec
        -2, //ct
        -4, //ti
        -2, //io
        1, //on
        0, //n-space
        0, //space-B
        2, //Be
        -1, //er
        -5, //rl
        -6, //li
        -2
    ],
    sonya_schoenberger_and_nele_guentheroth: [
        // Sonya Schönberger
        0,
        1, //So
        1.5, //on
        -0.3, //ny
        -0.9, //ya
        -4.5, //a-space
        0, //space-S
        0, //Sc
        1, //ch
        1.5, //hö
        1.5, //ön
        1.8, //nb
        2, //be
        -0.8, //er
        -2, //rg
        1, //ge
        -1, //er
        -8, // r-space
        0, //space-&
        -3, // &-space
        0, // space-N
        3, // Ne
        -2.7, // el
        -2.5, // le
        -3, // e-space
        0, //space-G
        0.8, // Gü
        0.6, // ün
        -2, // nt
        -0.5, // th
        1, // he
        -1, // er
        -2, // ro
        -2, // ot
        -1, // th
    ],
    eva_funk: [
            // Eva Funk
            0,
            0.5, //Ev
            0, //va
            0, //a-space
            0, //space-F
            1, //Fu
            2, //un
            1.5, //nk
    ],
    eric_meier: [
        // Eric Meier
        0,
        -0.5, //Er
        -4.5, //ri
        -3, //ic
        0, //c-space
        0, //space-M
        6, //Me
        -2.5, //ei
        -2.5, //ie
        -0.5 //er
    ],
    julius_c_schreiner: [
        // Julius C. Schreiner
        0,
        0.5, //Ju
        -1.5, //ul
        -5, //li
        -2, //iu
        1, //us
        0, //s-space
        0, //space-C
        -1, //C.
        0, //.-space
        -3, //space-S
        0.5, //Sc
        1, //ch
        0, //hr
        -1, //re
        -1.5, //ei
        -1.5, //in
        1.5, //ne
        -0.5 //er
    ],
    helin_ulas: [
        // Helin Ulas
        0,
        3, //He
        -2, //el
        -5.5, //li
        -1.5, //in
        0, //n-space
        0, //space-U
        0, //Ul
        -2.5, //la
        0.5 //as
    ],
    katharina_luedicke: [
        // Katharina Lüdicke
        0,
        2.5, //Ka
        -1.5, //at
        0, //th
        1, //ha
        -0.5, //ar
        -4.5, //ri
        -2, //in
        1.5, //na
        0, //a-space
        0, //space-L
        1.5, //Lü
        1.5, //üd
        -1.5, //di
        -2.5, //ic
        1, //ck
        0.5 //ke
    ],
    christin_kaiser: [
        // Christin Kaiser
        0,
        1.5, //Ch
        -0.5, //hr
        -4.5, //ri
        -3, //is
        -2, //st
        -3.5, //ti
        -2, //in
        0, //n-space
        0, //space-K
        3, //Ka
        -2, //ai
        -3, //is
        0.5, //se
        -0.5, //er
    ],
    heike_kabisch_and_katharina_kiebacher: [
        // Heike Kabisch
        0,
        3.2, //He
        -2.8, //ei
        -2.9, //ik
        0.5, //ke
        0, //e-space
        -1, //space-K
        2, //Ka
        1.5, //ab
        -2, //bi
        -3, //is
        0, //sc
        1, //ch
        0, //h-space
        -3, //space-&
        -3, //&-space
        0, //space-K
        2, // Ka
        -2, //at
        -1, //th
        0, //ha
        -2, //ar
        -5, //ri
        -2.5, //in
        0, //na
        0, //a-space
        0, //space-K
        -1.5, // Ki
        -2.6, //ie
        0.5, //eb
        0.9, //ba
        0, //ac
        0, //ch
        0.2, //he
        -2, //er
    ],
    heike_kabisch: [
        
        // Heike Kabisch
        0,
        3.2, //He
        -2.8, //ei
        -2.9, //ik
        0.5, //ke
        0, //e-space
        -1, //space-K
        2, //Ka
        1.5, //ab
        -2, //bi
        -3, //is
        0, //sc
        1 //ch
    ],
    alexandra_leykauf: [
        // Alexandra
        0,
        0.5, //Al
        -2.5, //le
        0.5, //ex
        0.5, //xa
        1, //an
        1.5, //nd
        -0.5, //dr
        -2, //ra
        0, //a-space
        0, //space-L
        1, //Le
        0, //ey
        0, //yk
        0, //ka
        0.5, //au
        -1, //uf
    ],
    jand_k: [
        // J&K
        0,
        3, //J&
        5 //&K
    ],
    isaac_wai_chong: [
        // Isaac Wai Chong
        0,
        -3, //Is
        0.25, //sa
        0.75, //aa
        0, //ac
        0, //c-space
        2, //space-W
        5, //Wa
        -2.5, //ai
        -3, //i-space
        0, //space-C
        1, //Ch
        1.5, //ho
        1.5, //on
        1.5 //ng
    ],
    roseline_rannoch: [
        // Roseline Rannoch
        0,
        2, //Ro
        0, //os
        0.5, //se
        -2.5, //el
        -5.5, //li
        -2, //in
        1, //ne
        0, //e-space
        0, //space-R
        2.5, //Ra
        1, //an
        1, //nn
        1, //no
        1, //oc
        1 //ch
    ],
    ruohan_wang: [
        // Ruohan Wang
        0,
        1.5, //Ru
        1, //uo
        1, //oh
        0.5, //ha
        0.5, //an
        0, //n-space
        0, //space-W
        4, //Wa
        0.5, //an
        1 //ng
    ],
}

export default kerning











// [
//     // Open all day
//     0,
//     2.5, //Op
//     1, //pe
//     1, //en
//     0, //n-space
//     0, //space-a
//     -3, //al
//     -6.5, //ll
//     0, //l-space
//     0, //space-d
//     1, //da
//     -0.5 //ay
// ],

// [
//     // All Artists
//     0,
//     0.5, //Al
//     -6.5, //ll
//     0, //l-space
//     0, //space-A
//     1.5, //Ar
//     -3, //rt
//     -4, //ti
//     -3, //is
//     -2, //st
//     -1 //ts
// ],

// [
//     // Open now!
//     0,
//     2.5, //Op
//     1.5, //pe
//     0.5, //en
//     0, //n-space
//     0, //space-n
//     3, //no
//     3, //ow
//     1 //w!
// ]


// [
//     // Wednesday
//     0,
//     5.5, //We
//     2, //ed
//     2, //dn
//     1.5, //ne
//     0.5, //es
//     1.5, //sd
//     1.5, //da
//     0 //ay
// ],