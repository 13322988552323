import React from 'react'
import style from './Buttons.module.scss'
import ButtonWrapper from './ButtonWrapper'

const icon = {
    height: 30,
    width: 40
}

const svgProps = {
    height: icon.height,
    width: icon.width,
    viewBox: `0 0 ${icon.width} ${icon.height}`
}

const stroke = {
    strokeWidth: 2,
    fill: 'none',
}

const ButtonInfo = ({ onClick, active, guide }) => {

    const close = {
        stroke: 'white',
        d: `
            M 1 ${icon.height-1},
            L ${icon.width/2} 1,
            L ${icon.width-1} ${icon.height-1},
            L 1 ${icon.height-1}
        `
    }

    const open = {
        stroke: 'none',
        fill: 'black',
        d: `
            M 1 1,
            L ${icon.width/2} ${icon.height-1},
            L ${icon.width-1} 1,
            L 1 1
        `
    }

    return(
        <ButtonWrapper onClick={onClick} guide={guide}>
            <svg {...svgProps}>
                <path {...stroke} className={style.path}
                    {...active ? open : close}
                />
            </svg>
        </ButtonWrapper>

    )
}


const ButtonCamera = ({ onClick, active, guide, className, color }) => {
    let cx = 15
    let cy = 10

    const svgProps = {
        height: icon.height,
        width: icon.width,
        viewBox: `0 0 ${icon.width} ${icon.height}`
    }

    const close = {
        stroke: color || 'white',
        d: `
            M ${cx} 1,
            L 1 1,
            L 1 ${cy},
            M 1 ${icon.height-cy},
            L 1 ${icon.height-1},
            L ${cx} ${icon.height-1},
            M ${icon.width-cx} ${icon.height-1},
            L ${icon.width-1} ${icon.height-1},
            L ${icon.width-1} ${icon.height-cy},
            M ${icon.width-1} ${cy},
            L ${icon.width-1} 1,
            L ${icon.width-cx} 1,
        `
    }

    cx = 8
    cy = 8

    const open = {
        stroke: 'red',
        d: `
            M ${cx} 1,
            L 1 1,
            L 1 ${cy},
            M 1 ${icon.height-cy},
            L 1 ${icon.height-1},
            L ${cx} ${icon.height-1},
            M ${icon.width-cx} ${icon.height-1},
            L ${icon.width} ${icon.height-1},
            L ${icon.width} ${icon.height-cy},
            M ${icon.width} ${cy},
            L ${icon.width} 1,
            L ${icon.width-cx} 1,
        `
    }

    return(
        <ButtonWrapper onClick={onClick} className={className} guide={guide}>
            <svg {...svgProps}>
                <path {...stroke} className={style.path}
                    {...active ? open : close}
                />
                {active ? <circle fill="red" cx={icon.width/2} cy={icon.height/2} r={7} /> : false}
            </svg>
        </ButtonWrapper>

    )
}

const ButtonLocation = ({ onClick, active, guide, className, color }) => {
    let pointer = 5

    const close = {
        stroke: color || 'white',
        d: `
            M ${icon.width-pointer} 1,
            L ${1+pointer} ${icon.height/2},
            L ${icon.width/3-1+pointer} ${icon.height/2+3},
            L ${icon.width/2-pointer/2} ${icon.height-1},
            L ${icon.width-pointer} 1,
        `
    }

    const open = {
        stroke: 'red',
        fill: 'red',
        d: `
            M ${icon.width-pointer} 1,
            L ${1+pointer} ${icon.height/2},
            L ${icon.width/3-1+pointer} ${icon.height/2+3},
            L ${icon.width/2-pointer/2} ${icon.height-1},
            L ${icon.width-pointer} 1,
        `
    }

    return(
        <ButtonWrapper onClick={onClick} className={className} guide={guide}>
            <svg {...svgProps}>
                <path {...stroke} className={style.path}
                    {...active ? open : close}
                />
            </svg>
        </ButtonWrapper>

    )
}


// const ButtonSwitch = ({ onClick, active, guide, className }) => {
//     let cx = 15
//     let cy = 10
//     let pointer = 5

//     const close = {
//         stroke: 'white',
//         d: `
//             M 1 1,
//             L 1 ${icon.height-1},
//             L ${icon.width-1} ${icon.height-1},
//             L ${icon.width-1} 1,
//             L 1 1,
//         `
//     }

//     cx = 8
//     cy = 8

//     const open = {
//         stroke: 'white',
//         // fill: 'red',
//         d: `
//             M 10 10,
//             L 1 ${icon.height-10},
//             L ${icon.width-1} ${icon.height-10},
//             L ${icon.width-10} 10,
//             L 10 10,
//         `
//     }

//     return(
//         <ButtonWrapper onClick={onClick} className={className} guide={guide}>
//             <svg {...svgProps}>
//                 <path {...stroke} className={style.path}
//                     {...active ? open : close}
//                 />
//             </svg>
//         </ButtonWrapper>

//     )
// }


const ButtonSwitch = ({ onClick, active, guide, className }) => {

    const close = {
        stroke: 'white',
        d: `
            M 1 1,
            L 1 ${icon.height-1},
            L ${icon.width-1} ${icon.height-1},
            L ${icon.width-1} 1,
            L 1 1,
        `
    }

    const open = {
        stroke: 'white',
        // fill: 'red',
        d: `
            M 10 10,
            L 1 ${icon.height-10},
            L ${icon.width-1} ${icon.height-10},
            L ${icon.width-10} 10,
            L 10 10,
        `
    }

    // const paths = [
    //     {
    //         close: {
    //             stroke: 'white',
    //             d: `
    //                 M 1 1,
    //                 L 1 ${icon.height-1},
    //                 L ${icon.width-1} ${icon.height-1},
    //                 L ${icon.width-1} 1,
    //                 L 1 1,
    //             `
    //         },
    //         open: {
    //             stroke: 'white',
    //             // fill: 'red',
    //             d: `
    //                 M 10 10,
    //                 L 1 ${icon.height-10},
    //                 L ${icon.width-1} ${icon.height-10},
    //                 L ${icon.width-10} 10,
    //                 L 10 10,
    //             `
    //         }
    //     }
    // ]

    return(
        <ButtonWrapper onClick={onClick} className={className} guide={guide}>
            <svg {...svgProps}>
                <path {...stroke} className={style.path}
                    {...active ? open : close}
                />
            </svg>
        </ButtonWrapper>

    )
}



export { ButtonInfo, ButtonLocation, ButtonCamera, ButtonSwitch }
